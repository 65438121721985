.customRow{
    padding: 0rem !important;
  }
  
.customColumn{
    padding: 0rem !important;
  
  }

  .email{
    background-color: rgba(0,0,0,.9) !important;
    color: white !important
  
  }